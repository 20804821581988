import React, { memo } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { styled } from '@mui/material'

import slugPrefix, { slugPrefixProps } from 'helpers/slug-prefix'
import Button, { ButtonProps } from '@atoms/buttons/Button'

export interface InternalButtonLinkProps extends ButtonProps, slugPrefixProps {
  children: React.ReactNode
}

const StyledButton = styled(Button)(({ theme }) => ({
  ['&:hover']: {
    color: 'inherit',
  },
}))

const InternalButtonLink = ({
  slug,
  apiKey,
  children,
  ...props
}: InternalButtonLinkProps) => {
  return (
    <StyledButton
      // @ts-ignore
      to={slugPrefix({ slug: slug, apiKey: apiKey })}
      component={GatsbyLink}
      {...props}
    >
      {children}
    </StyledButton>
  )
}
export default memo(InternalButtonLink)
