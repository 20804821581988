export interface slugPrefixProps {
  slug?: string
  apiKey?: string
}

const slugPrefix = ({ slug, apiKey }: slugPrefixProps) => {
  if (!slug) {
    return '/'
  }
  if (apiKey) {
    switch (apiKey) {
      case 'blog_category':
        return `/blog/${slug}`
    }
  }
  return `/${slug}`
}

export default slugPrefix
